module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"A blog by Alberto Arena","short_name":"A blog by Alberto Arena","theme_color":"hsl(31, 92%, 62%)","background_color":"hsl(0, 0%, 100%)","icon":"content/photo.jpg","display":"standalone","start_url":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0936e97f9d28d92a72b6436a8991928f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-cookieconsent/gatsby-browser.js'),
      options: {"plugins":[],"cookieConsentConfig":{"categories":{"necessary":{"enabled":true,"readOnly":true},"analytics":{}},"language":{"default":"en","translations":{"en":{"consentModal":{"title":"Cookie usage","description":"The usage of cookies helps to improve the usage of this website.","acceptAllBtn":"Accept all","acceptNecessaryBtn":"Reject all","showPreferencesBtn":"Manage Individual preferences"},"preferencesModal":{"title":"Manage cookie preferences","acceptAllBtn":"Accept all","acceptNecessaryBtn":"Reject all","savePreferencesBtn":"Accept current selection","closeIconLabel":"Close modal","sections":[{"title":"Type of Cookies","description":"Allowing Analytics cookies helps to improve the website."},{"title":"Strictly Necessary cookies","description":"These cookies are essential for the proper functioning of the website and cannot be disabled.","linkedCategory":"necessary"},{"title":"Performance and Analytics","description":"These cookies collect information about how you use the website. All of the data is anonymized and cannot be used to identify you.","linkedCategory":"analytics"},{"title":"More information","description":"For any queries in relation to our policy on cookies and your choices, please see our <a href=\"/...\">Privacy Policy</a> or <a href=\"/...\">contact us</a>."}]}}}}},"enableForAllEnvironments":true,"googleGtagPluginConfig":{"trackingIds":["G-PJGZWDSK4K"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
